<template lang="pug">
modal-card(title='Crear un equipo' width='500px')
  devices-form(@saved='deviceSaved')
</template>

<script>
import DevicesForm from './DevicesForm.vue'

export default {
  components: {
    DevicesForm
  },
  props: {},
  data () {
    return {
      saving: false,
      location: this.locationUpdated ? { ...this.locationUpdated } : { icon: 0 }
    }
  },
  methods: {
    deviceSaved () {
      this.$parent.close()
    }
  }
}
</script>
