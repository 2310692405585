<template lang="pug">
app-form(@submit='submit' :is-saving='saving' :horizontal='false')
  text-field(label='Nombre' placeholder='Ejm: Placa' :maxlength='30' required v-model='device.name')
  text-field(label='Id' :maxlength='15' placeholder='IMEI en la mayoría de los casos' required v-model='device.id')
  select-field(label='Cliente' :items='clients' required v-model.number='device.clientId')
  select-field(label='Propósito' required v-model.number='device.porpuse' :enum='devicePorpuseEnum' :enum-names='devicePorpuseDescriptions')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { devicePorpuseEnum, devicePorpuseDescriptions } from '@/constants/enums'

export default {
  data () {
    return {
      devicePorpuseEnum,
      devicePorpuseDescriptions,
      isNew: true,
      device: {
        id: '',
        name: '',
        clientId: null,
        porpuse: devicePorpuseEnum.Tracking
      }
    }
  },
  created () {
    this.getClients()
  },
  computed: {
    ...mapState({
      clientsLoading: state => state.clients.loading,
      error: state => state.clients.error,
      clients: state => state.clients.list,
      saving: state => state.devices.savingLoading
    })
  },
  methods: {
    ...mapActions({
      getClients: 'clients/getClients',
      getDevices: 'devices/getDevices',
      save: 'devices/save'
    }),
    async submit () {
      const device = { isNew: this.isNew, ...this.device }
      const result = await this.save(device)
      if (result) {
        this.getDevices()
        this.$emit('saved')
      }
    }
  }
}
</script>
